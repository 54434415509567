/* eslint-disable default-case */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
export const getEditId = () => {
    if (window.localStorage.getItem("EditId")) {
        return Number(window.localStorage.getItem("EditId"));
    }
    else
        return 0;
}

export const setEditId = (data) => {
    try {
        window.localStorage.setItem("EditId", data);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}


export const getIsPCLogin = () => {
    if (window.localStorage.getItem("IsPCLogin")) {
        return window.localStorage.getItem("IsPCLogin") == true || window.localStorage.getItem("IsPCLogin") == 'true';
    }
    else
        return 0;
}


export const setIsPCLogin = (data) => {
    try {
        window.localStorage.setItem("IsPCLogin", data);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const GetProvinceList = () => {
    return [
        { value: 1, text: "Eastern Cape" },
        { value: 2, text: "Free State" },
        { value: 3, text: "Gauteng" },
        { value: 4, text: "KwaZulu-Natal" },
        { value: 5, text: "Limpopo" },
        { value: 6, text: "Mpumalanga" },
        { value: 7, text: "Northern Cape" },
        { value: 8, text: "North West" },
        { value: 9, text: "Western Cape" },
    ];
}

export const GetProvinceName = (ProvinceValue) => {
    const provinces = GetProvinceList();
    return ProvinceValue ? provinces.find(p => p.value === ProvinceValue).text : "N/A";
}

export const GetUserTypes = () => {
    return [{ text: "Supplier", value: 10 }, { text: "Administrator", value: 100 }];
}

export const GetUserTypeName = (UserType) => {
    switch (UserType) {
        case 10:
            return "Supplier";
        case 100:
            return "Administrator";
        default:
            return "N/A";
    }
}
