/* eslint-disable default-case */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, ExcelExport, Filter, GridComponent, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GetProvinceName, getIsPCLogin, setEditId } from '../helpers/Lookup';
import { IsAuthenticated, getAuthToken } from '../helpers/authentication';
import { confirm } from 'react-confirm-box';
import { showToast } from '../helpers/ToastHelper';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { fieldRequired } from '../helpers/validation';
import moment from 'moment';

class Visitors extends Component {
    static grid = null;

    constructor(props) {
        super(props);

        this.state = {
            VisitorList: [],
            loading: true,
            showImport: false,
            showImportResult: false,
            FileData: "",
            ImportResult: null,
        }

        this.toggleImport = this.toggleImport.bind(this);
        this.toggleImportResult = this.toggleImportResult.bind(this);
        this.handleImportFileChange = this.handleImportFileChange.bind(this);
        this.StartImport = this.StartImport.bind(this);
    }

    toggleImport(evt) {
        evt.stopPropagation();
        this.setState({ showImport: !this.state.showImport });
    }

    toggleImportResult(evt) {
        evt.stopPropagation();
        this.setState({ showImportResult: !this.state.showImportResult });
    }

    componentDidMount() {
        IsAuthenticated().then((res) => {
            if (res) {
                document.title = "Est Africa :: Visitor Administration";
                this.loadData();
            }
        }).catch(() => window.location.href = "/login");
    }

    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/visitors', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ VisitorList: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    window.location.href = "/login";
            }

        } catch (e) {
            console.error(e);
        }
    }

    editItem = (id) => {
        setEditId(id);
        window.location.href = "/visitor-details"
    };

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Edit':
                this.editItem(args.rowData.id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.id);
                break;
            case "Download":
                this.DownloadQRCode(args.rowData.id);
                break;
            default:
                return;
        }
    }

    async deleteData(dataId) {
        ThrobbleHelper.toggleThrobble(true, "Deleting Visitor");
        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/visitors/'+ dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                // await response.json();
                this.loadData();
                showToast(
                    "success",
                    "Visitors",
                    "The visitor was successfully deleted!"
                );
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    window.location.href = "/login";
                showToast(
                    "error",
                    "Visitors",
                    "There was an error deleting the visitor"
                );
                ThrobbleHelper.toggleThrobble(false);

            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            showToast(
                "error",
                "Visitors",
                "There was an error deleting the visitor"
            );
            ThrobbleHelper.toggleThrobble(false);

        }
    }

    renderVisitors(VisitorsData) {

        const commands = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: "Download", title: "Download QR Code Image", buttonOption: { cssClass: "e-flat", iconCss: "e-download e-icons" }, },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
        ];

        return (
            <GridComponent dataSource={VisitorsData} allowExcelExport={true} commandClick={this.onGridCommand} allowFiltering={true} allowSorting={true} filterSettings={{ type: "Menu" }}
                allowPaging={true} pageSettings={{ pageSize: 25 }} ref={e => Visitors.grid = e}>
                <ColumnsDirective>
                    <ColumnDirective field='firstName' headerText="First Name" />
                    <ColumnDirective field='lastName' headerText="Last Name" />
                    <ColumnDirective field='emailAddress' headerText="Email Address" />
                    <ColumnDirective field='mobile' headerText="Mobile" />
                    <ColumnDirective field='company' headerText="Company" />
                    <ColumnDirective headerText='Actions' commands={commands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, Filter, ExcelExport, Page, CommandColumn, Toolbar]} />
            </GridComponent>
        )
    }

    RenderProvince(props) {
        return <>{GetProvinceName(props.province)}</>;
    }


    async toolbarClick(type) {

        let fileName = "Est_Africa_" + moment().format("DD_MMMM_YYYY") + "_visitors_report.xlsx";
        switch (type) {
            case "All":
                if (Visitors.grid) {
                    ThrobbleHelper.toggleThrobble(true, "Exporting Visitors Data")
                    const exportProps = {
                        fileName
                    }
                    Visitors.grid.excelExport(exportProps).then(res => {
                        if (res.worksheets.length > 0) {
                            showToast("success", "Visitor Excel Export", "Visitors Data successfully exported");
                        } else {
                            showToast("error", "Visitor Excel Export", "There was an error exporting visitors data");
                        }
                    });
                }
                break;
            default:
                return;
        }


    }


    render() {

        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderVisitors(this.state.VisitorList);

        return (
            <>
                <div className="container">
                    <Row className='mb-4'>
                        <Col xs={6}>
                            <h1>Visitors Administration</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            {/* <Button color='primary' size='sm' onClick={this.toggleImport} hidden={!getIsPCLogin()}>
                                <i className='far fa-upload me-2'></i>Import Visitors</Button> */}
                            <Button size='sm' className="btn-purple" outline onClick={() => this.toolbarClick("All")}><i className='fa fa-file-excel me-2'></i>Export Visitors</Button>
                            <Button color='dark' outline size='sm' className='mx-2' onClick={this.DownloadBulkQRs}>
                                <i className='far fa-download me-2'></i>Download All QR Codes</Button>
                            {/* <Button className="btn-purple mx-2" size="sm" onClick={this.RefreshQrCodes} hidden={!getIsPCLogin()}>
                                <i className="far fa-rotate me-2"></i>Refresh QR Codes
                            </Button> */}
                            <Button color="primary" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.showImport} fade toggle={this.toggleImport}>
                    <ModalHeader toggle={this.toggleImport} close={<button
                        className="btn-close"
                        onClick={this.toggleImport}
                    ></button>} >
                        Import Visitors
                    </ModalHeader>
                    <ModalBody>

                        <label>Import File <small className='text-muted'>(excel)</small>
                            <span id="errFile" className='ErrorText' /></label>
                        <Input type='file' accept='.xlsx, .xls' onChange={this.handleImportFileChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' size='sm' onClick={this.toggleImport}>Close</Button>
                        <Button color='success' size='sm' onClick={this.StartImport}>Import</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showImportResult} fade toggle={this.toggleImportResult}>
                    <ModalHeader toggle={this.toggleImportResult} close={<button
                        className="btn-close"
                        onClick={this.toggletoggleImportResultImport}
                    ></button>} >
                        Visitors Import Results
                    </ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardBody>
                                {this.state.ImportResult &&
                                    <>
                                        <i className='fas fa-check-circle me-2 text-success'></i>Successes: {this.state.ImportResult.successes}<br />
                                        <i className='fas fa-times-circle me-2 text-danger'></i>Errors: {this.state.ImportResult.errors}<br />
                                        <i className='fas fa-check-circle me-2 text-warning'></i>Existing: {this.state.ImportResult.existing}<br />
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' size='sm' onClick={this.toggleImportResult}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    async StartImport(evt) {
        evt.stopPropagation();
        let isValid = true;
        isValid &= fieldRequired(this.state.FileData, "errFile", "* Required");

        if (isValid) {
            const bearer = "Bearer " + getAuthToken();
            ThrobbleHelper.toggleThrobble(true, "Importing Visitors");
            try {
                const response = await fetch("/api/visitors/ImportVisitors", {
                    method: "POST",
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ FileData: this.state.FileData })
                });

                if (response.ok) {

                    const data = await response.json();
                    this.loadData();
                    // showToast("success", "Visitor Import", "Visited were successfully imported!");
                    this.setState({ ImportResult: data, showImportResult: true });
                    ThrobbleHelper.toggleThrobble(false);

                } else {
                    if (response.status === 401)
                        window.location.href = "/login";
                    else {
                        showToast("error", "Visitor Import", "There was an error importing visitors");
                        ThrobbleHelper.toggleThrobble(false);
                    }
                }
            } catch (e) {
                console.error(e);
                showToast("error", "Visitor Import", "There was an error importing visitors");
                ThrobbleHelper.toggleThrobble(false);
            }
        }

    }

    handleImportFileChange(evt) {
        const SelectedFile = evt.target.files[0];
        if (SelectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const FileData = e.target.result;
                this.setState({
                    FileData
                });
            }
            reader.readAsDataURL(SelectedFile);
        }
    }

    RenderActionColumn(props) {
        return (
            <>
                <span
                    className="e-flat mx-1"
                    title="Edit"
                    onClick={(e) => this.onGridCommand("Edit", props.id)}
                >
                    <i className="e-edit e-icons"></i>
                </span>
                <span
                    className="e-flat mx-1"
                    title="Generate QR Code"
                    onClick={(e) => this.onGridCommand("Download", props.id)}
                >
                    <i className="e-repeat e-icons"></i>{" "}
                </span>
                {/* } */}
                <span
                    className="e-flat-mx-1"
                    title="Delete"
                    onClick={(e) => this.onGridCommand("Download", props.id)}
                >
                    <i className="e-delete e-icons"></i>
                </span>
            </>
        );
    }


    async DownloadQRCode(Id) {
        ThrobbleHelper.toggleThrobble(true, "Downloading QR Code");
        const bearer = "Bearer " + getAuthToken();

        const response = await fetch("/api/visitors/DownloadQRCode/" + Id, {
            method: "GET",
            headers: {
                Authorization: bearer,
                "Content-Type": "image/png",
            },
        });

        if (response.ok) {
            const data = await response.blob();
            const contentDisposition = response.headers.get("content-disposition");
            const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
            const fileName = fileNameMatch
                ? fileNameMatch[1].replace(/"/g, "")
                : "unknown";

            // Create a URL from the response blob
            const fileUrl = URL.createObjectURL(data);
            const LinkBtn = document.createElement("a");
            LinkBtn.download = fileName;
            LinkBtn.href = fileUrl;
            LinkBtn.click();
            showToast(
                "success",
                "QR Code Download",
                "QR Code Image was successfully downloaded"
            );
        } else {
            if (response.status === 401) window.location.href = "/login";
            else {
                showToast(
                    "error",
                    "QR Code Download",
                    "There was an error downloading QR Code Image"
                );
            }
        }
    }

    async DownloadBulkQRs() {
        ThrobbleHelper.toggleThrobble(true, "Downloading QR Codes in Bulk");
        const bearer = "Bearer " + getAuthToken();
        const response = await fetch("/api/visitors/DownloadBulkQRCode", {
            method: "GET",
            headers: {
                Authorization: bearer,
                "Accept": "application/zip",
            },
        });
        if (response.ok) {
            const data = await response.blob();
            // Create a URL from the response blob
            const fileUrl = URL.createObjectURL(data);
            const LinkBtn = document.createElement("a");
            LinkBtn.download = "Est_Africa_Visitors_qr_codes.zip";
            LinkBtn.href = fileUrl;
            LinkBtn.click();
            showToast(
                "success",
                "QR Codes Bulk Download",
                "QR Code Images were successfully downloaded."
            );
            ThrobbleHelper.toggleThrobble(false);
        } else {
            if (response.status === 401) window.location.href = "/login";
            else {
                showToast(
                    "error",
                    "QR Codes Bulk Download",
                    "There was an error downloading the QR Code Images"
                );
                ThrobbleHelper.toggleThrobble(false);
            }
        }
    }

    async RefreshQrCodes() {
        try {
            ThrobbleHelper.toggleThrobble(true, "Refreshing QR Code");
            const bearer = "Bearer " + getAuthToken();
            const response = await fetch("/api/visitors/RefreshQRCodes", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "image/png",
                },
            });

            if (response.ok) {
                showToast("success", "Refresh QR Codes", "The QR codes were successfully refreshed");
                ThrobbleHelper.toggleThrobble(false);
                this.loadData();
            }
            else {
                showToast("error", "Refresh QR Codes", "There was a problem refreshing the QR codes");
                ThrobbleHelper.toggleThrobble(false);
            }
        }
        catch (e) {
            console.error(e);
            showToast("error", "Refresh QR Codes", "There was a problem refreshing the QR codes");
            ThrobbleHelper.toggleThrobble(false);
        }
    }
}

export default Visitors;