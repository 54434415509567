import React, { Component } from 'react'
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { IsAuthenticated, getAuthToken } from '../helpers/authentication';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { showToast } from '../helpers/ToastHelper';
import { ColumnDirective, ColumnsDirective, ExcelExport, Filter, GridComponent, Inject, Page, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

export default class VisitsReport extends Component {

  static grid = null;
  constructor(props) {
    super(props);

    this.state = {
      VisitsData: [],
      SupplierList: [],
      VisitorList: [],
      SupplierName: "",
      SupplierId: 0,
      VisitorId: 0,
      VisitorName: "",
      loading: true
    }
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        document.title = "Est Africa :: Visits Report";

        ThrobbleHelper.toggleThrobble(true, "Loading Report");
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  async loadData() {
    const bearer = "Bearer " + getAuthToken();
    try {

      const response = await fetch("/api/visitslog/GetVisitsReport", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ VisitsData: data });
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login"
      }
    } catch (e) {
      console.error("Visit Log:", e);
    }

    try {
      const VisitorReponse = await fetch("/api/visitors",
        {
          method: 'GET',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          }
        });

      if (VisitorReponse.ok) {
        const data = await VisitorReponse.json();
        const VisitorList = [{ id: 0, fullname: "- All Visitors -" }, ...(data.map(item => {
          return { id: item.id, fullname: item.firstName + " " + item.lastName };
        }))]
        this.setState({ VisitorList });
      }
    } catch (e) {
      console.error("Visitors: ", e);
    }

    try {
      const SupplierResponse = await fetch("/api/suppliers", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (SupplierResponse.ok) {
        const data = await SupplierResponse.json();
        const SupplierList = [{ id: 0, name: "- All Suppliers -" }, ...data];
        this.setState({ SupplierList, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      } else {
        console.log(SupplierResponse.status + ": " + SupplierResponse.statusText);
        if (SupplierResponse.status === 401)
          window.location.href = "/login";

        this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
    }
    catch (e) {
      console.error("Supplier:", e)
      this.setState({ loading: false });
    }
  }

  async toolbarClick(type) {

    let fileName = "Est_Africa_" + moment().format("DD_MMMM_YYYY") + "_visits_report.xlsx"

    switch (type) {
      case "All":
        if (VisitsReport.grid) {
          ThrobbleHelper.toggleThrobble(true, "Exporting Visits Excel Report")
          const exportProps = {
            fileName
          }
          VisitsReport.grid.excelExport(exportProps).then(res => {
            if (res.worksheets.length > 0) {
              showToast("success", "Visits Excel Export", "Visits report successfully exported");
            } else {
              showToast("error", "Visits Excel Export", "There was an error exporting visits report");
            }
          });
        }
        break;
      default:
        return;
    }


  }

  renderReport(SupplierId, VisitorId, ReportData) {
    if (SupplierId > 0)
      ReportData = ReportData.filter(c => c.supplierId === SupplierId);

    if (VisitorId > 0)
      ReportData = ReportData.filter(c => c.visitorId === VisitorId);
    ReportData.map(i => i.dateVisited = new Date(i.dateVisited));

    return (
      <GridComponent id="grid" dataSource={ReportData} allowExcelExport={true} allowFiltering={true} filterSettings={{ type: "Menu" }} allowPaging={true}
        allowSorting={true} sortSettings={{ columns: [{ field: "dateVisited", direction: "Descending" }] }} pageSettings={{ pageSize: 25 }} ref={e => VisitsReport.grid = e}>
        <ColumnsDirective>
          <ColumnDirective field='supplierName' width={"200"} headerText='Supplier' textAlign='Left' />
          <ColumnDirective field='visitorName' width={"200"} headerText='Visitor Name' textAlign='Left' />
          <ColumnDirective field='visitorEmail' width={"200"} headerText='Email Address' textAlign='Left' />
          <ColumnDirective field='visitorContact' width={"200"} headerText='Contact No.' textAlign='Left' />
          <ColumnDirective field='visitorCompany' width={"200"} headerText='Company' textAlign='Left' />
          <ColumnDirective field="dateVisited" width={"200"} headerText="Date and Time Visited" format={{ type: "dateTime", format: "dd MMMM yyyy HH:mm" }} textAlign='Left' />

        </ColumnsDirective>
        <Inject services={[Toolbar, ExcelExport, Filter, Page, Sort, Search]} />
      </GridComponent>
    )
  }

  clearSelectedSupplier() {
    this.setState({ SupplierId: 0 });
  }

  clearSelectedVisitor() {
    this.setState({ VisitorId: 0 });
  }

  render() {
    const content = this.state.loading ? <em></em> : this.renderReport(this.state.SupplierId, this.state.VisitorId, this.state.VisitsData);
    return (
      <div className='container'>
        <div className="row">
          <div className="col-md-4 mb-3">
            <h1>Visits Reports</h1>
          </div>
          <div className="col-md-8 align-self-center text-end">
            <Button size='sm' className="btn-purple" outline onClick={() => this.toolbarClick("All")}><i className='fa fa-file-excel me-2'></i>Export Visits</Button>
            {/* <Button size='sm' className="btn-purple ms-2" outline onClick={() => this.toolbarClick("Completed")} hidden={this.state.SupplierId === 0}><i className='fa fa-file-excel me-2'></i>Export User Completed</Button> */}
          </div>
          <div className="col-12 mb-3">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-6">
                    <label>Filter By Supplier</label>
                    <DropDownListComponent fields={{ value: "id", text: "name" }} dataSource={this.state.SupplierList} value={this.state.SupplierId} filterType='Contains' allowFiltering={true} select={e => this.setState({ SupplierId: e.itemData.id, SupplierName: e.itemData.name })} />
                    <button className='e-control e-btn e-btn-danger' onClick={this.clearSelectedSupplier = this.clearSelectedSupplier.bind(this)} hidden={this.state.SupplierId == 0}>&times; Reset Supplier</button>
                  </div>
                  <div className="col-md-6">
                    <label>Filter By Visitor</label>
                    <DropDownListComponent fields={{ value: "id", text: "fullname" }} dataSource={this.state.VisitorList} value={this.state.VisitorId} filterType='Contains' allowFiltering={true} select={e => this.setState({ VisitorId: e.itemData.id, VisitorName: e.itemData.name })} />
                    <button className='e-control e-btn' onClick={this.clearSelectedVisitor = this.clearSelectedVisitor.bind(this)} hidden={this.state.VisitorId == 0}>&times; Reset Visitor</button>

                  </div>
                </div>


              </CardBody>
            </Card>
          </div>
          <div className="col-12">
            {content}
          </div>
        </div>
      </div>
    )
  }
}
