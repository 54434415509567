import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Outlet } from 'react-router-dom';
import ThrobbleHelper from '../helpers/ThrobbleHelper';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className='position-relative'>
        <NavMenu />
        <Container>
          <Outlet />
        </Container>
        <ThrobbleHelper />
      </div>
    );
  }
}
