/* eslint-disable default-case */
import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems, Page } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { IsAuthenticated, getAuthToken } from '../helpers/authentication';
import { fieldRequired } from '../helpers/validation';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import moment from 'moment';

export class Suppliers extends Component {

  static grid = null;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Name: '',
    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        document.title = "Est Africa :: Suppliers Administration";
        ThrobbleHelper.toggleThrobble(true, "Loading Suppliers")
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      this.setState({ Id: data.id, Name: data.name });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Name: '',
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;

    if (valid) {
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} allowExcelExport={true} allowPaging={true} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar}
        ref={e => Suppliers.grid = e}>
        <ColumnsDirective>
          <ColumnDirective field='name' width='100' headerText="Name" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, Page, ExcelExport, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  async toolbarClick(type) {
    let fileName = "Est_Africa" + moment().format("DD_MMMM_YYYY") + "_suppliers_report.xlsx";
    switch (type) {
      case "All":
        if (Suppliers.grid) {
          ThrobbleHelper.toggleThrobble(true, "Exporting Suppliers Excel Data")
          const exportProps = {
            fileName
          }
          Suppliers.grid.excelExport(exportProps).then(res => {
            if (res.worksheets.length > 0) {
              showToast("success", "Suppliers Excel Export", "Suppliers Data successfully exported");
            } else {
              showToast("error", "Suppliers Excel Export", "There was an error exporting suppliers data");
            }
          });
        }
        break;
      default:
        return;
    }


  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Suppliers.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row className='mb-4'>
            <Col xs={6}>
              <h1>Suppliers Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button size='sm' className="btn-purple mx-2" outline onClick={() => this.toolbarClick("All")}><i className='fa fa-file-excel me-2'></i>Export Suppliers</Button>

              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="btn-close" onClick={this.toggle}></button>}>Edit Suppliers</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Auto' value={this.state.Name} onChange={e => this.setState({ Name: e.value }, () => fieldRequired(e.value, "errName", "* Required"))} /> <div id='tbNameError' className='error-message' />
              <div id="errName" className="ErrorText"></div>
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/suppliers', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    ThrobbleHelper.toggleThrobble(true, "Saving Supplier")
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Name: this.state.Name }

    try {
      const response = await fetch('api/suppliers', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        showToast(
          "success",
          "Suppliers",
          "The suppliers was successfully saved!"
        );
        this.loadData();
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
        ThrobbleHelper.toggleThrobble(false);
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      showToast(
        "error",
        "Suppliers",
        "There was an error saving supplier"
      );
      ThrobbleHelper.toggleThrobble(false);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      ThrobbleHelper.toggleThrobble(true, "Deleting Supplier")
      const response = await fetch('api/suppliers/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        showToast(
          "success",
          "Suppliers",
          "The suppliers was successfully deleted!"
        );
        this.loadData();
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      showToast(
        "error",
        "Suppliers",
        "There was an error deleting supplier"
      );
      ThrobbleHelper.toggleThrobble(false);
    }
  }
}

