import React, { Component } from "react";
import { IsAuthenticated, getAuthToken } from "../helpers/authentication";
import {
  GetProvinceList,
  GetUserTypes,
  getEditId,
  setEditId,
} from "../helpers/Lookup";
import { Button, Input, Spinner } from "reactstrap";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { fieldRequired } from "../helpers/validation";
import { showToast } from "../helpers/ToastHelper";
import ThrobbleHelper from "../helpers/ThrobbleHelper";

export default class VisitorDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // UserTypeList: ,
      EditId: 0,
      Id: 0,
      UserId: "",
      UserType: 10,
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      Mobile: "",
      WorkNumber: "",
      Company: "",
      Designation: "",
      Province: 0,
      Password: "",
      QRImage: "",
      isPasswordValid: true,
      loading: true,
    };

    this.SaveData = this.SaveData.bind(this);
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        ThrobbleHelper.toggleThrobble(true, "Loading Visitor Details");
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  async loadData() {
    const UserId = getEditId();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/visitors/" + UserId, {
        method: "GET",
        headers: {
          Authorization: bearer,
          ContentType: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.id > 0) {
          this.setState({
            EditId: data.id,
            Id: data.id,
            UserType: data.userType,
            FirstName: data.firstName,
            LastName: data.lastName,
            EmailAddress: data.emailAddress,
            Password: "",
            SupplierId: data.supplierId,
            QRImage: data.QRImage,
            Mobile: data.mobile,
            WorkNumber: data.workNumber,
            Company: data.company,
            Designation: data.designation,
            Province: data.province,
            LockedOut: data.lockedOut,
            loading: false,
          });
          ThrobbleHelper.toggleThrobble(false);
        } else {
          this.setState({ loading: false });
          ThrobbleHelper.toggleThrobble(false);
        }
      } else {
        console.log(response.status + ": " + response.statusText);
        this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  renderUserForm(state) {
    const ProvinceList = GetProvinceList();
    return (
      <>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>
              First Name
              <span className="ErrorText ms-1">*</span>
              <span id="errFirstname" className="ErrorText"></span>
            </label>
            <Input
              type="text"
              bsSize={"sm"}
              value={state.FirstName}
              onChange={(e) =>
                this.setState({ FirstName: e.target.value }, () =>
                  fieldRequired(
                    this.state.FirstName,
                    "errFirstname",
                    " Required"
                  )
                )
              }
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              Last Name
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errLastname" className="ErrorText "></span>
            </label>
            <Input
              type="text"
              bsSize={"sm"}
              value={state.LastName}
              onChange={(e) =>
                this.setState({ LastName: e.target.value }, () =>
                  fieldRequired(this.state.LastName, "errLastname", " Required")
                )
              }
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              Email Address
            </label>
            <Input
              type="email"
              bsSize={"sm"}
              value={state.EmailAddress}
              onChange={(e) =>
                this.setState({ EmailAddress: e.target.value })
              }
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              Mobile
            </label>
            <Input
              type="text"
              bsSize={"sm"}
              value={state.Mobile}
              onChange={(e) =>
                this.setState({ Mobile: e.target.value })
              }
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Work Number</label>
            <Input
              type="text"
              bsSize={"sm"}
              value={state.WorkNumber}
              onChange={(e) => this.setState({ WorkNumber: e.target.value })}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Company</label>
            <Input
              type="text"
              bsSize={"sm"}
              value={state.Company}
              onChange={(e) => this.setState({ Company: e.target.value })}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Designation</label>
            <Input
              type="text"
              bsSize="sm"
              value={state.Designation}
              onChange={(e) => this.setState({ Designation: e.target.value })}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Province</label>
            {/* <Input type='text' bsSize={"sm"}value={state.Company} onChange={e => this.setState({ Company: e.target.value }, () => fieldRequired(this.state.Company, "errCompany", " Required"))} /> */}
            <DropDownListComponent
              dataSource={ProvinceList}
              fields={{ text: "text", value: "value" }}
              value={state.Province}
              placeholder="- Select Province -"
              select={(e) => {
                this.setState({ Province: e.itemData.value });
              }}
            />
          </div>
        </div>
        <hr />
        <div className="text-end ">
          <Button size="sm" color="success" onClick={this.SaveData}>
            <i className="far fa-check-circle me-2"></i> Save{" "}
          </Button>
        </div>
      </>
    );
  }

  async SaveData(evt) {
    evt.stopPropagation();
    const bearer = "Bearer " + getAuthToken();
    try {
      let isValid = true;
      isValid &= fieldRequired(
        this.state.FirstName,
        "errFirstname",
        " Required"
      );
      isValid &= fieldRequired(this.state.LastName, "errLastname", " Required");

      if (isValid) {
        ThrobbleHelper.toggleThrobble(true, "Saving visitor data");
        let data = {
          Id: this.state.Id,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          EmailAddress: this.state.EmailAddress,
          Mobile: this.state.Mobile,
          WorkNumber: this.state.WorkNumber,
          Company: this.state.Company,
          Designation: this.state.Designation,
          Province: this.state.Province,
        };

        const response = await fetch("/api/visitors", {
          method: "POST",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          const EditId = await response.json();
          setEditId(EditId);
          showToast(
            "success",
            "Visitor Details",
            "Visitor was successfully saved!"
          );
          this.loadData();
        } else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401) window.location.href = "/login";
          else if (response.status === 409) {
            showToast(
              "error",
              "Visitor Details",
              "Email address already exist in the system"
            );
          } else
            showToast(
              "error",
              "Visitor Details",
              "There was an error saving visitor"
            );
          ThrobbleHelper.toggleThrobble(false);
        }
      }
    } catch (e) {
      console.error(e);
      showToast(
        "error",
        "Visitor Details",
        "There was an error saving visitor"
      );
      ThrobbleHelper.toggleThrobble(false)
    }
  }

  render() {
    const content = this.state.loading ? (
      <em>
        <Spinner className="me-2" /> Loading Visitor data...{" "}
      </em>
    ) : (
      this.renderUserForm(this.state)
    );
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>Visitor Details</h1>
            </div>
            <div className="col-md-6 align-self-center text-end">
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => (window.location.href = "/visitors")}
              >
                <i className="far fa-arrow-circle-left me-2"></i>Back to List
              </button>
            </div>
          </div>
          <div className="mt-3">{content}</div>
        </div>
      </>
    );
  }
}
